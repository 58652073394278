
/* eslint-disable @typescript-eslint/camelcase */
import parsingErrorResponse from "@/app/infrastructures/misc/common-library/ParsingErrorResponse";
import {
  formatDateWithoutTime,
  parseInputFloatToFloat,
} from "@/app/infrastructures/misc/Utils";
import { CashbackConfigurationController } from "@/app/ui/controllers/CashbackConfigController";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import { TopupFeeConfigurationController } from "@/app/ui/controllers/TopupFeeConfigurationController";
import { CashbackConfigRequest } from "@/data/payload/api/CashbackApiRequest";
import { RequestListPartner } from "@/data/payload/api/PartnerApiRequest";
import { ApiStateData } from "@/domain/entities/Api";
import { LastBalanceData } from "@/domain/entities/LastBalance";
import { ModalMessageEntities } from "@/domain/entities/MainApp";
import { ResponsePayloadV2 } from "@/domain/entities/ResponsePayload";
import { FlagsPermissionTopUpAndCashback } from "@/feature-flags/flags-permission-topup-and-cashback";
import { debounce } from "lodash";
import moment from "moment";
import { Options, Vue } from "vue-class-component";
import SectionTiersCalculation from "../create/section-tiers-calculation.vue";

@Options({
  components: {
    SectionTiersCalculation,
  },
  beforeRouteLeave(to: any, from: any, next: any) {
    if (to.name === "login") {
      next();
    }
    if (this.answerLeavingPage) {
      next();
    } else {
      this.leavePageConfirmation = true;
      this.nextPath = to.path;
      next(false);
    }
  },
})
export default class EditCashbackConfiguration extends Vue {
  get isEditAble() {
    return FlagsPermissionTopUpAndCashback.permission_finance_cashback_enable.isEnabled();
  }

  goBack() {
    this.$router.push(`/finance/top-up-and-cashback/cashback/${this.detailData.data.ccbId}`);
  }

  // route logic
  leavePageConfirmation = false;
  nextPath = "";
  answerLeavingPage = false;
  onLeavePage() {
    this.answerLeavingPage = true;
    this.$router.push(this.nextPath);
  }
  onCloseSuccess() {
    MainAppController.closeMessageModal();
    this.answerLeavingPage = true;
    this.goBack();
  }

  mounted() {
    this.fetchDetail();
  }

  get id() {
    return this.$route.params.id;
  }
  get form() {
    return CashbackConfigurationController.form;
  }

  get posName() {
    return this.detailData.data.ccbIsAllPos
      ? "Semua POS"
      : this.detailData.data.ccbPartners
          ?.map((key: any) => " " + key.name)
          .toString();
  }

  get accountTypeValue() {
    return (
      this.detailData.data.ccbAccountType[0].toUpperCase() +
      this.detailData.data.ccbAccountType.substring(1)
    );
  }
  get ccbTypeValue() {
    return "Persentase"
  }

  apiDetailData = new ApiStateData();
  detailData = new ResponsePayloadV2();

  async fetchDetail() {
    try {
      MainAppController.showLoading();
      this.apiDetailData.loading = true;
      this.detailData =
        await CashbackConfigurationController.GetDetailCashbackConfig(this.id);
      this.apiDetailData.errorType = "";
      CashbackConfigurationController.setPeriodeStart(
        new Date(this.detailData.data.ccbStartDate)
      );
      CashbackConfigurationController.setPeriodeEnd(
        new Date(this.detailData.data.ccbEndDate)
      );
      CashbackConfigurationController.setSearchPartner(
        this.detailData.data.ccbIsAllPos ? [{ "id": "all", "name": "Semua POS" }] : this.detailData.data.ccbPartners
      );
      this.form.ccbTiers = this.detailData.data.ccbTiers;
      this.form.ccbIsAllPoss = this.detailData.data.ccbIsAllPos
      this.form.ccbPartnerIds =
        this.searchPartner.filter((key: any) => key.id === "all").length > 0
          ? []
          : this.searchPartner.map((key: any) => key.id);
    } catch (error) {
      this.apiDetailData.errorType =
        parsingErrorResponse(error).type === "internet-error"
          ? "internet"
          : parsingErrorResponse(error).type;
    } finally {
      MainAppController.closeLoading();
      this.apiDetailData.loading = false;
    }
  }

  listPos: any = [];
  isLoadingPartner = false;

  async onGetListPartner(search: string) {
    try {
      this.isLoadingPartner = true;
      this.listPos = await TopupFeeConfigurationController.getListPartner(
        new RequestListPartner({
          version: "v2",
          search,
          type: "pos",
          limit: 10,
          countryCode: "ID",
          status: "not-banned",
        })
      );
      this.listPos = [
        {
          id: "all",
          name: "Semua POS",
        },
        ...this.listPos.data.map((key: LastBalanceData) => {
          return {
            id: key.idClientPartner,
            name: key.nameClientPartner,
          };
        }),
      ];
    } catch (error) {
      MainAppController.showErrorMessage(
        parsingErrorResponse(error, "Gagal Mendapatkan List Partner!", () =>
          this.onGetListPartner(search)
        )
      );
    } finally {
      this.isLoadingPartner = false;
    }
  }

  filterPos = debounce((search: string) => {
    if (search.length > 2 || !search) this.onGetListPartner(search);
  }, 250);

  get searchPartner() {
    return CashbackConfigurationController.searchPartner;
  }

  onResetFilter() {
    CashbackConfigurationController.setResetFilter([]);
  }

  onSelectPartner(value: any) {
    const includesAll =
      this.searchPartner.filter((key: any) => key.id === "all").length > 0;
    this.form.ccbIsAllPoss = includesAll;
    this.form.ccbPartnerIds = includesAll
      ? []
      : this.searchPartner.map((key: any) => key.id);
  }

  invalidEndDate = false;
  get minDateEndDate() {
    const minDate = new Date(this.detailData.data.formatStartDate);
    minDate.setDate(minDate.getDate() + 1);
    return minDate;
  }
  get periodeEnd(): any {
    return CashbackConfigurationController.convertPeriodeEnd;
  }
  get periodeStart(): any {
    return CashbackConfigurationController.convertPeriodeStart;
  }

  inputDateEnd(param: Date) {
    CashbackConfigurationController.setPeriodeEnd(param);
    if (
      formatDateWithoutTime(new Date()) ===
      formatDateWithoutTime(new Date(param))
    ) {
      if (
        param.getHours() === new Date().getHours() - 1 &&
        param.getMinutes() >= new Date().getMinutes()
      ) {
        this.invalidEndDate = false;
      } else
        this.invalidEndDate = param.getHours() <= new Date().getHours() - 1;
    }
  }

  get isFormValid() {
    return (
      CashbackConfigurationController.convertPeriodeStart !== "" &&
      CashbackConfigurationController.convertPeriodeEnd !== "" &&
      CashbackConfigurationController.searchPartner.length &&
      !this.form.ccbTiers.find(
        (item: any) => !item.min_balance || !item.percentage
      ) &&
      CashbackConfigurationController.isErrorMinTopUp &&
      CashbackConfigurationController.isErrorMaxTopUp &&
      CashbackConfigurationController.isErrorPercentage
    );
  }

  onShowModalConfirm() {
    MainAppController.showMessageModal(
      new ModalMessageEntities({
        title: "Simpan Perubahan “Konfigurasi Cashback”?",
        message:
          "Pastikan kembali konfigurasi cashback yang diperbarui sudah benar dan sesuai",
        textSuccess: "Ya",
        textCancel: "Tidak",
        onClose: () => MainAppController.closeMessageModal(),
        onSubmit: () => this.onSubmit(),
        image: "badge-confirmation-general",
      })
    );
  }

  async onSubmit() {
    MainAppController.closeMessageModal();
    MainAppController.closeErrorMessage();
    MainAppController.showLoading();
    const payload = new CashbackConfigRequest({
      ccbAccountType: this.form.ccbAcountType.toLowerCase(),
      ccbType: this.form.ccbType.toLowerCase(),
      ccbIsAllPos: this.form.ccbIsAllPoss,
      ccbPartnerIds: this.form.ccbPartnerIds,
      ccbStartDate: moment(
        CashbackConfigurationController.convertPeriodeStart
      ).format("YYYY-MM-DD"),
      ccbEndDate: moment(
        CashbackConfigurationController.convertPeriodeEnd
      ).format("YYYY-MM-DD"),
      ccbTiers: this.form.ccbTiers.map((item: any) => {
        return {
          no: item.no,
          minBalance: parseInputFloatToFloat(item.min_balance.toString()),
          maxBalance: !item.max_balance
            ? null
            : parseInputFloatToFloat(item.max_balance.toString()),
          percentage: parseInputFloatToFloat(item.percentage.toString()),
        };
      }),
    });
    try {
      await CashbackConfigurationController.EditCashbackConfig({payload, id: this.id});
      MainAppController.showMessageModal(
        new ModalMessageEntities({
          title: "Konfigurasi Cashback Berhasil Diperbarui!",
          message: "Perubahan konfigurasi cashback telah tersimpan",
          textSuccess: "OK",
          onSubmit: () => this.onCloseSuccess(),
          image: "image-modal-success",
        })
      );
    } catch (err) {
      MainAppController.showErrorMessage(
        parsingErrorResponse(err, "Konfigurasi Cashback Gagal Diperbarui!")
      );
    } finally {
      MainAppController.closeLoading();
    }
  }
}
