
/* eslint-disable @typescript-eslint/camelcase */
import { Options, Vue } from "vue-class-component";
import SectionPartnerCriteria from "./section-partner-criteria.vue";
import SectionCashbackRules from "./section-cashback-rules.vue";
import SectionTiersCalculation from "./section-tiers-calculation.vue";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import { CashbackConfigRequest } from "@/data/payload/api/CashbackApiRequest";
import { CashbackConfigurationController } from "@/app/ui/controllers/CashbackConfigController";
import { ModalMessageEntities } from "@/domain/entities/MainApp";
import {
  parseInputFloatToFloat,
  parsingErrorResponse,
} from "@/app/infrastructures/misc/Utils";
import moment from "moment";

@Options({
  components: {
    SectionPartnerCriteria,
    SectionCashbackRules,
    SectionTiersCalculation,
  },
  beforeRouteLeave(to: any, from: any, next: any) {
    if (to.name === "login") {
      next();
    }
    if (this.answerLeavingPage) {
      next();
    } else {
      this.leavePageConfirmation = true;
      this.nextPath = to.path;
      next(false);
    }
  },
})
export default class CreateCashback extends Vue {
  mounted() {
    CashbackConfigurationController.setResetForm();
  }
  goBack() {
    this.$router.push("/finance/top-up-and-cashback/cashback");
  }

  // route logic
  leavePageConfirmation = false;
  nextPath = "";
  answerLeavingPage = false;
  onLeavePage() {
    this.answerLeavingPage = true;
    this.$router.push(this.nextPath);
  }
  onCloseSuccess() {
    MainAppController.closeMessageModal();
    this.answerLeavingPage = true;
    this.goBack();
  }

  get isFormValid() {
    return (
      CashbackConfigurationController.convertPeriodeStart !== "" &&
      CashbackConfigurationController.convertPeriodeEnd !== "" &&
      CashbackConfigurationController.searchPartner.length &&
      !this.form.ccbTiers.find(
        (item: any) => !item.min_balance || !item.percentage
      ) &&
      CashbackConfigurationController.isErrorMinTopUp &&
      CashbackConfigurationController.isErrorMaxTopUp &&
      CashbackConfigurationController.isErrorPercentage
    );
  }

  get form() {
    return CashbackConfigurationController.form;
  }

  async onSubmit() {
    MainAppController.closeMessageModal();
    MainAppController.closeErrorMessage();
    MainAppController.showLoading();
    const payload = new CashbackConfigRequest({
      ccbAccountType: this.form.ccbAcountType.toLowerCase(),
      ccbType: this.form.ccbType.toLowerCase(),
      ccbIsAllPos: this.form.ccbIsAllPoss,
      ccbPartnerIds: this.form.ccbPartnerIds,
      ccbStartDate: moment(
        CashbackConfigurationController.convertPeriodeStart
      ).format("YYYY-MM-DD"),
      ccbEndDate: moment(
        CashbackConfigurationController.convertPeriodeEnd
      ).format("YYYY-MM-DD"),
      ccbTiers: this.form.ccbTiers.map((item: any) => {
        return {
          no: item.no,
          minBalance: parseInputFloatToFloat(item.min_balance),
          maxBalance: !item.max_balance ? null : parseInputFloatToFloat(item.max_balance),
          percentage: parseInputFloatToFloat(item.percentage),
        };
      }),
    });
    try {
      await CashbackConfigurationController.CreateCashbackConfig(payload);
      MainAppController.showMessageModal(
        new ModalMessageEntities({
          title: "Konfigurasi Cashback Berhasil Dibuat!",
          message: "Konfigurasi Cashback telah dibuat",
          textSuccess: "OK",
          onSubmit: () => this.onCloseSuccess(),
          image: "image-modal-success",
        })
      );
    } catch (err) {
      MainAppController.showErrorMessage(
        parsingErrorResponse(err, "Konfigurasi Cashback Gagal Dibuat!")
      );
    } finally {
      MainAppController.closeLoading();
    }
  }
}
