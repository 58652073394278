
/* eslint-disable @typescript-eslint/camelcase */
import {
  formatInputMoneyWithDecimal,
  formatInputNumberOnly,
  parseInputFloatToFloat,
} from "@/app/infrastructures/misc/Utils";
import { CashbackConfigurationController } from "@/app/ui/controllers/CashbackConfigController";
import { Vue } from "vue-class-component";

export default class SectionTiersCalculation extends Vue {
  mounted() {
    CashbackConfigurationController.setIsErrorValidationTiers(false);
  }
  get form() {
    return CashbackConfigurationController.form;
  }

  addTiers() {
    this.form.ccbTiers.push({
      no: this.form.ccbTiers.length + 1,
      min_balance: this.form.ccbTiers[this.form.ccbTiers.length - 1].max_balance,
      max_balance: 0,
      percentage: 0,
    });
  }
  removeTiers(index: number) {
    this.form.ccbTiers.splice(index, 1);
    this.form.ccbTiers[index].min_balance =
      this.form.ccbTiers[index - 1].max_balance;
  }
  formatTiers(value: string) {
    return formatInputNumberOnly(value);
  }
  formatDecimal(value: string) {
    return formatInputMoneyWithDecimal(value, 2);
  }
  isErrorMinTopUp(index: number) {
    CashbackConfigurationController.setIsErrorMinTopUp(
      !(
        parseInputFloatToFloat(
          this.form.ccbTiers[index].min_balance.toString()
        ) < 1000
      )
    );
    if (!this.form.ccbTiers[index].min_balance) return;
    return {
      error: parseInputFloatToFloat(this.form.ccbTiers[index].min_balance.toString()) <
      1000,
      errorCaption: `Minimum nilai Rp1.000.`,
    };
  }
  isErrorMaxTopUp(index: number) {
    CashbackConfigurationController.setIsErrorMaxTopUp(
      this.form.ccbTiers.length - 1 === index
        ? true
        : !(
            parseInputFloatToFloat(
              this.form.ccbTiers[index].max_balance.toString()
            ) <
              parseInputFloatToFloat(
                this.form.ccbTiers[index].min_balance.toString()
              ) || !this.form.ccbTiers[index].max_balance
          )
    );
    if (!this.form.ccbTiers[index].max_balance) return;
    return {
      error:  this.form.ccbTiers.length - 1 === index &&
      !this.form.ccbTiers[index].max_balance
        ? false
        : parseInputFloatToFloat(
            this.form.ccbTiers[index].max_balance.toString()
          ) <=
          parseInputFloatToFloat(
            this.form.ccbTiers[index].min_balance.toString()
          ),
      errorCaption: `Nominal harus lebih besar dari minimum`,
    };
  }

  isErrorPercentage(index: number) {
    CashbackConfigurationController.setIsErrorPercentage(
      !(
        parseInputFloatToFloat(
          this.form.ccbTiers[index].percentage.toString()
        ) < 0.1 ||
        parseInputFloatToFloat(
          this.form.ccbTiers[index].percentage.toString()
        ) > 100
      )
    );
    if (!this.form.ccbTiers[index].percentage) return;
    return {
      error: parseInputFloatToFloat(this.form.ccbTiers[index].percentage.toString()) <
        0.1 ||
      parseInputFloatToFloat(this.form.ccbTiers[index].percentage.toString()) >
        100,
      errorCaption:
        parseInputFloatToFloat(
          this.form.ccbTiers[index].percentage.toString()
        ) > 100
          ? `Maximum cashback 100%.`
          : `Minimum cashback 0.1%.`,
    };
  }
  changeMaxTopUp(val: any, index: number) {
    this.form.ccbTiers[index + 1].min_balance = val;
  }
  get ableToAddTierss() {
    return this.form.ccbTiers.length < 10;
  }
  get ableToRemoveTierss() {
    return this.form.ccbTiers.length > 1;
  }
}
