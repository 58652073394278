
import { parsingErrorResponse } from "@/app/infrastructures/misc/Utils";
import { CashbackConfigurationController } from "@/app/ui/controllers/CashbackConfigController";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import { PosController } from "@/app/ui/controllers/PosController";
import { TopupFeeConfigurationController } from "@/app/ui/controllers/TopupFeeConfigurationController";
import { RequestListPartner } from "@/data/payload/api/PartnerApiRequest";
import { LastBalanceData } from "@/domain/entities/LastBalance";
import { debounce } from "lodash";
import { Vue } from "vue-class-component";

export default class SectionPartnerCriteria extends Vue {
  mounted() {
    this.onGetListPartner("");
  }
  get form() {
    return CashbackConfigurationController.form;
  }
  listPos: any = [];
  isLoadingPartner = false;

  async onGetListPartner(search: string) {
    try {
      this.isLoadingPartner = true;
      this.listPos = await TopupFeeConfigurationController.getListPartner(
        new RequestListPartner({
          version: "v2",
          search,
          type: "pos",
          limit: 10,
          countryCode: "ID",
          status: "not-banned",
        })
      );
      this.listPos = [
        {
          id: "all",
          name: "Semua POS",
        },
        ...this.listPos.data.map((key: LastBalanceData) => {
          return {
            id: key.idClientPartner,
            name: key.nameClientPartner,
          };
        }),
      ];
    } catch (error) {
      MainAppController.showErrorMessage(
        parsingErrorResponse(error, "Gagal Mendapatkan List Partner!", () =>
          this.onGetListPartner(search)
        )
      );
    } finally {
      this.isLoadingPartner = false;
    }
  }

  filterPos = debounce((search: string) => {
    if (search.length > 2 || !search) this.onGetListPartner(search);
  }, 250);

  get searchPartner() {
    return CashbackConfigurationController.searchPartner;
  }

  get isLoadingPOS() {
    return PosController.isLoading;
  }

  onResetFilter() {
    CashbackConfigurationController.setResetFilter([])
  }

  onSelectPartner(value: any) {
    const includesAll =
      this.searchPartner.filter((key: any) => key.id === "all").length > 0;
    this.form.ccbIsAllPoss = includesAll;
    this.form.ccbPartnerIds = includesAll
      ? []
      : this.searchPartner.map((key: any) => key.id);
  }
}
