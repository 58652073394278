import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-row space-x-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Title = _resolveComponent("Title")!
  const _component_LpInput = _resolveComponent("LpInput")!
  const _component_DataWrapper = _resolveComponent("DataWrapper")!
  const _component_MultipleSelectSearch = _resolveComponent("MultipleSelectSearch")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_Title, {
      label: "Kriteria Partner",
      class: "text-20px"
    }),
    _createVNode("div", _hoisted_1, [
      _createVNode(_component_DataWrapper, {
        label: "Tipe Akun",
        asterisk: "",
        class: "w-4/12"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_LpInput, {
            modelValue: _ctx.form.ccbAcountType,
            disabled: "",
            disableBackground: ""
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_DataWrapper, {
        label: "Nama POS",
        asterisk: "",
        class: "w-4/12"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_MultipleSelectSearch, {
            "modal-title": "Nama POS",
            "option-name": "name",
            onFocus: _ctx.filterPos,
            onFilter: _ctx.filterPos,
            onChange: _ctx.onSelectPartner,
            options: _ctx.listPos,
            modelValue: _ctx.searchPartner,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.searchPartner = $event)),
            keyName: "name",
            keyValue: "id",
            placeholder: "Ketik atau pilih nama pos",
            isLoading: _ctx.isLoadingPartner,
            showReset: "",
            "able-to-delete-in-modal": "",
            customDisplayAll: "Semua POS",
            onReset: _ctx.onResetFilter
          }, null, 8, ["onFocus", "onFilter", "onChange", "options", "modelValue", "isLoading", "onReset"])
        ]),
        _: 1
      })
    ])
  ], 64))
}