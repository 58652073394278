import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-row space-x-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Title = _resolveComponent("Title")!
  const _component_Pickers = _resolveComponent("Pickers")!
  const _component_DataWrapper = _resolveComponent("DataWrapper")!
  const _component_LpInput = _resolveComponent("LpInput")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_Title, {
      label: "Aturan Cashback",
      class: "text-20px"
    }),
    _createVNode("div", _hoisted_1, [
      _createVNode(_component_DataWrapper, {
        label: "Tanggal Mulai",
        asterisk: "",
        class: "w-4/12"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Pickers, {
            modelValue: _ctx.periodeStart,
            onSelected: _ctx.inputDateStart,
            minDate: _ctx.minDate
          }, null, 8, ["modelValue", "onSelected", "minDate"])
        ]),
        _: 1
      }),
      _createVNode(_component_DataWrapper, {
        label: "Tanggal Berakhir",
        asterisk: "",
        class: "w-4/12"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Pickers, {
            modelValue: _ctx.periodeEnd,
            onSelected: _ctx.inputDateEnd,
            minDate: _ctx.minDateEndDate
          }, null, 8, ["modelValue", "onSelected", "minDate"])
        ]),
        _: 1
      })
    ]),
    _createVNode(_component_DataWrapper, {
      label: "Tipe Cashback",
      asterisk: "",
      class: "w-14/20 capitalize"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_LpInput, {
          modelValue: _ctx.ccbTypeValue,
          disabled: "",
          disableBackground: ""
        }, null, 8, ["modelValue"])
      ]),
      _: 1
    })
  ], 64))
}