
import { formatDateWithoutTime } from "@/app/infrastructures/misc/Utils";
import { CashbackConfigurationController } from "@/app/ui/controllers/CashbackConfigController";
import { Vue } from "vue-class-component";

export default class SectionCashbackRules extends Vue {
  get form() {
    return CashbackConfigurationController.form;
  }
  get ccbTypeValue() {
    return "Persentase"
  }
  // Date
  invalidStartDate = false;
  invalidEndDate = false;
  get periodeStart(): any {
    return CashbackConfigurationController.convertPeriodeStart;
  }
  get minDate() {
    const date = new Date();
    const minDate = new Date(date);
    minDate.setDate(minDate.getDate() + 1);
    return minDate;
  }
  get minDateEndDate() {
    const minDate = new Date(this.periodeStart);
    minDate.setDate(minDate.getDate() + 1);
    return minDate;
  }
  get periodeEnd(): any {
    return CashbackConfigurationController.convertPeriodeEnd;
  }
  inputDateStart(param: Date) {
    CashbackConfigurationController.setPeriodeStart(param);
    CashbackConfigurationController.setPeriodeEnd("");
    if (
      formatDateWithoutTime(new Date()) ===
      formatDateWithoutTime(new Date(param))
    ) {
      if (
        param.getHours() === new Date().getHours() - 1 &&
        param.getMinutes() >= new Date().getMinutes()
      ) {
        this.invalidStartDate = false;
      } else
        this.invalidStartDate = param.getHours() <= new Date().getHours() - 1;
    }
  }
  inputDateEnd(param: Date) {
    CashbackConfigurationController.setPeriodeEnd(param);
    if (
      formatDateWithoutTime(new Date()) ===
      formatDateWithoutTime(new Date(param))
    ) {
      if (
        param.getHours() === new Date().getHours() - 1 &&
        param.getMinutes() >= new Date().getMinutes()
      ) {
        this.invalidEndDate = false;
      } else
        this.invalidEndDate = param.getHours() <= new Date().getHours() - 1;
    }
  }
}
