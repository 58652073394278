
import parsingErrorResponse from "@/app/infrastructures/misc/common-library/ParsingErrorResponse";
import { formatPrice } from "@/app/infrastructures/misc/Utils";
import { CashbackConfigurationController } from "@/app/ui/controllers/CashbackConfigController";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import { ApiStateData } from "@/domain/entities/Api";
import { ResponsePayloadV2 } from "@/domain/entities/ResponsePayload";
import { FlagsPermissionTopUpAndCashback } from "@/feature-flags/flags-permission-topup-and-cashback";
import { Vue } from "vue-class-component";

export default class DetailCashbackConfiguration extends Vue {
  get isEditAble() {
    return FlagsPermissionTopUpAndCashback.permission_finance_cashback_enable.isEnabled();
  }

  goBack() {
    this.$router.push(`/finance/top-up-and-cashback/cashback`)
  }

  mounted() {
    this.fetchDetail();
  }

  get id() {
    return this.$route.params.id;
  }

  get ccbTypeValue() {
    return "Persentase"
  }

  get posName() {
    return this.detailData.data.ccbIsAllPos
      ? "Semua POS"
      : this.detailData.data.ccbPartners
          ?.map((key: any) => " " + key.name)
          .toString();
  }

  apiDetailData = new ApiStateData();
  detailData = new ResponsePayloadV2();

  async fetchDetail() {
    try {
      MainAppController.showLoading();
      this.apiDetailData.loading = true;
      this.detailData =
        await CashbackConfigurationController.GetDetailCashbackConfig(this.id);
      this.apiDetailData.errorType = "";
    } catch (error) {
      this.apiDetailData.errorType =
        parsingErrorResponse(error).type === "internet-error"
          ? "internet"
          : parsingErrorResponse(error).type;
    } finally {
      MainAppController.closeLoading();
      this.apiDetailData.loading = false;
    }
  }

  goToEdit() {
    this.$router.push(`/finance/top-up-and-cashback/cashback/${this.id}/edit`);
  }

  // table
  get columns(): any[] {
    return [
      {
        name: "Tier",
        styleHead: "w-6 text-left whitespace-no-wrap",
        render: (item: any, index: number) =>
          `<span class="font-normal text-black-lp-300 whitespace-no-wrap">${index + 1}</span>`,
      },
      {
        name: "Minimum Top Up",
        styleHead: "w-28 text-left whitespace-no-wrap",
        render: (item: any) =>
          `<span class="font-normal text-black-lp-300 whitespace-no-wrap">${formatPrice(item.min_balance)}</span>`,
        asterisk: true
      },
      {
        name: " ",
        styleHead: "w-6 text-left whitespace-no-wrap",
        render: (item: any) =>
          `<span class="font-normal text-black-lp-300 whitespace-no-wrap"><</span>`,
      },
      {
        name: "Maksimum Top Up",
        styleHead: "w-32 text-left whitespace-no-wrap",
        render: (item: any) =>
          `<span class="font-normal text-black-lp-300 whitespace-no-wrap">${formatPrice(item.max_balance)}</span>`,
        asterisk: true
      },
      {
        name: "Cashback",
        styleHead: "w-24 text-left whitespace-no-wrap",
        render: (item: any) =>
          `<span class="font-normal text-black-lp-300 whitespace-no-wrap">${item.percentage}%</span>`,
        asterisk: true
      },
    ];
  }
}
